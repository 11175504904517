*{
    box-sizing: border-box;
}

.container{
    max-width: 100%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto' !important;
    font-size: 13px;

    .loading{
        top: 0;
        position: absolute !important;
        min-width: 100vw;
        min-height: 100vh;
        background-color: #4e4e4e34;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg{
            width: 50px;
            height: 50px;
            color:#FB6700;
            transform: translate(-50%, -50%);
            animation: spin 2s linear infinite;
        }
    
        @keyframes spin {
            0% {
              transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
              transform: translate(-50%, -50%) rotate(360deg);
            }
          }
    }

    .hr {
        border: none !important;
        border-top: 2px solid #000 !important;
        margin: 20px 0 !important;
      }

    .linerisk{
        text-decoration: line-through;
        color:#818389;
    }

    .orange{
        color:#FB6700;
        font-weight: bold;
    }

    .grey{
        color:#818389;
        font-weight: 300 !important;
    }

    .grey-bg{
        background-color: #F5F6F8;
    }

    .border{
        padding:5px 0px;
        border: 1px solid #818389;
        border-radius:10px;

        div{
            padding: 5px;
        }
    }

    .headerList{
        width: 550px;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    h2{ 
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }

    .list{
        width: 550px;
        margin-top: 30px;
        gap: 20px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column;

        .listItem{
            padding: 20px 20px;
            width: 100%;
            height: auto;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .listItemHeader{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                gap: 10px;
                align-items: start;
                width: 100%;
                margin-bottom: 20px;
            }

             .infoBox{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: start;
                gap: 20px;
                background-color: #E1E4E6;
                border-radius: 10px;
                padding: 15px;
                color:#808488;
                svg{
                    font-size: 20px;
                    color:#6e6e6e;
                }
            }

            .subItem{
                width: 100%;
                display: flex;
                justify-content: space-between;
                
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    :nth-child(1){
                        font-weight: bold;
                    }
                }

                :nth-child(2){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                .subItemIcon{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;

                    svg{
                        color:#818389;
                        cursor: pointer;
                    }
                }
            }

            .total{
                width: 100%;
                border-radius: 10px;
                background-color: #F5F6F8;
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: bold;
                align-items: center;

                span{
                    padding: 14px;
                }

                :nth-child(2){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

            }

            button{
                cursor: pointer;
                margin-top:10px;
                background-color: #FB6700;
                color:#FFF;
                border-radius: 10px;
                border:none;
                padding: 10px;
                max-width: 250px;
            }

            button:hover{
                opacity: .9;
            }
        
        }        

    }

    .totalIntercambio{
        width: 100%;
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        span{
            font-weight: bold;
        }
    }

    .borderList{
        border: 1px solid #d1d1d1;
    }

    .listItemDrop{
        padding: 20px 20px;
        width: 100%;
        height: auto;
        background-color: #FFF;
        border-radius: 15px;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .listDrop{
        box-sizing: border-box;
        border-radius: 10px;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 0px;
        background-color: #FFF;

        .listDropHeader{
            padding: 15px 10px;
            cursor: pointer;
        }

        svg{
            color:#FB6700;
            cursor: pointer;
        }

        div {
            padding: 5px 5px;
            display: flex;
            justify-content: space-between;

            > span{
                font-weight: bold;
            }
        }

        .dropItem > div{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .end{
            justify-content: end !important;
        }
    }

}



@media screen and (max-width:600px) {

        .totalIntercambio{
            max-width: 90%;
        }

        .listDrop{
            max-width:  90% !important;
        }

        .list{
            width: 100% !important;
        }
        .headerList{
            font-size: 14px;
            width: 90% !important;

            button{
                width: 30px !important;
                height: 30px !important;
            }
        }
        .listItem{
            padding: 20px 20px !important;
            width: 90% !important;
            flex-wrap: wrap-reverse;

            .listDrop{
                max-width: 100% !important;
            }
            
        }


    
}
