*{
    box-sizing: border-box;
}


.hide{
    display: none;
}

.descMobile{
    display: none !important;
}

.mode{
    margin-top: 20px;
    width: 250px;
    height: 50px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .right{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin: 5px;
    }

    .left{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin: 5px;
    }

    .active{
        background-color: #ebebeb !important;
    }

    div{
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
   }

    div:hover{
        background-color: #ebebeb;
    }
}

.container{
    max-width: 100%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto' !important;
    font-size: 13px;




    select{
        margin-top: 30px;
        width: 100%;
        border: none;
        border-radius: 8px;
        height: 40px;
        border: 1px solid #FB6700;
        cursor: pointer;
        text-indent: 4px;
    }

  
    .saleWrapper{
        margin-top: 20px;
        width: 500px;
        border-radius: 10px;
        color:#FFF;
        background-color: #FB6700;
        justify-content: center;
        text-align: center;


        .saleWrapperHeader{
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px;
            cursor: pointer;
        }

        > h3{
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
        }



    }

    .listWrapper{
        color:#000;
        margin-top: 10px;
        width: 100%;
        padding: 20px;
        background-color: #FFF;
        border-radius: 10px;
        gap:20px;
        flex-direction: column;
        display: flex;
    
        .dropDownBoletos{

            .dropDownBoletosHeader{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color:#FB6700;
                padding: 10px;
                cursor: pointer;
            }

            width: 100%;
            border: 2px solid #FB6700;
            border-radius: 10px;
            height: 40px auto;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 10px;
        }

        .billBox{
            width: 100%;
            // border: 2px solid #FB6700;
            padding: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            overflow: hidden;
            color:#848389;            
            background-color: #F0F2F5;

            h3{
                color:#000;
            }

            article{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-weight:700;

                b{
                    color:#FB6700;
                }
            }

            .status{
                width: 100%;
                height: 25px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 0;
                color:#FFF;
                display: none;
            }

            .valortotal{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }


            .statusBill{
                color:#FFF;
                border-radius: 5px;
                padding: 2px;
                width: 100px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                gap: 10px;
            }

            .pago{
                border: 1px solid rgb(84, 182, 84);
                background-color: rgb(84, 182, 84);
            }

            .atrasado{
                background-color: rgb(255, 58, 58);
            }
            .aberto{
                background-color: rgb(128, 128, 128);
            }

            section{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                button{
                    cursor: pointer;
                    border: none;
                    background-color:#FB6700;
                    color:#FFF;
                    width: 250px;
                    padding: 10px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    text-transform: uppercase;
                    font-weight:400;
                }

                button:hover{
                    opacity: .9;
                }
            }

            div{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                span{
                    font-size: 10px;
                }
            }
        }

    }



    .loading{
        top: 0;
        position: absolute !important;
        min-width: 100vw;
        min-height: 100vh;
        background-color: #4e4e4e34;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg{
            width: 50px;
            height: 50px;
            color:#FB6700;
            transform: translate(-50%, -50%);
            animation: spin 2s linear infinite;
        }
    
        @keyframes spin {
            0% {
              transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
              transform: translate(-50%, -50%) rotate(360deg);
            }
          }
    }

    .hr {
        border: none !important;
        border-top: 2px solid #000 !important;
        margin: 20px 0 !important;
      }

    .linerisk{
        text-decoration: line-through;
        color:#818389;
    }

    .orange{
        color:#FB6700;
        font-weight: bold;
    }

    .grey{
        color:#818389;
        font-weight: 300 !important;
    }

    .grey-bg{
        background-color: #F5F6F8;
    }

    .border{
        padding:5px 0px;
        border: 1px solid #818389;
        border-radius:10px;

        div{
            padding: 5px;
        }
    }

    .docDropdown{
        margin-top: 10px;
        width: 550px;
        background-color: #FB6700;
        border-radius: 15px;
        
        .docDropdownHeader{
            color:#FFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding:25px;
            height: 80px;

            .docDropdownHeader{
                padding-bottom:27px;
            }
        }
    }

    

    .docList{
        width: 550px;
        height: auto;
        border-radius: 15px;
        background-color: #FFF;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .warning{
            background-color: rgb(255, 188, 188);
            padding: 10px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color:red;
        }

        .headerDocList{
            display: flex;
            flex-direction: column;
            b{
                padding-top: 10px;
            }
        }

        .docBox{
            padding: 20px;
            // border: 2px solid #FB6700;
            background-color: #F0F2F5;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 9999;

            div{
                display: flex;
                flex-direction: column;
                align-items: flex-start !important;
                justify-content: flex-start !important;
                text-align: start !important;
            }

            svg{
                font-size: 25px;
            }
        }

        .docBox:hover{
            background-color: #EBEBEB;
            cursor: pointer;
        }
    }

    .headerList{
        width: 550px;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    h2{ 
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }

    .list{
        width: 550px;
        margin-top: 10px;
        gap: 20px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column;

        .listItem{
            padding: 20px 20px;
            width: 100%;
            height: auto;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .listItemHeader{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                gap: 10px;
                align-items: start;
                width: 100%;
                margin-bottom: 20px;
            }

             .infoBox{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: start;
                gap: 20px;
                background-color: #E1E4E6;
                border-radius: 10px;
                padding: 15px;
                color:#808488;
                svg{
                    font-size: 20px;
                    color:#6e6e6e;
                }
            }

            .subItem{
                width: 100%;
                display: flex;
                justify-content: space-between;
                
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    :nth-child(1){
                        font-weight: bold;
                    }
                }

                :nth-child(2){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                .subItemIcon{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;

                    svg{
                        color:#818389;
                        cursor: pointer;
                    }
                }
            }

            .total{
                width: 100%;
                border-radius: 10px;
                background-color: #F5F6F8;
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: bold;
                align-items: center;

                span{
                    padding: 14px;
                }

                :nth-child(2){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

            }

            button{
                cursor: pointer;
                margin-top:10px;
                background-color: #FB6700;
                color:#FFF;
                border-radius: 10px;
                border:none;
                padding: 10px;
                max-width: 250px;
            }

            button:hover{
                opacity: .9;
            }
        
        }        

    }

    .totalIntercambio{
        width: 100%;
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        span{
            font-weight: bold;
        }
    }

    .borderList{
        border: 1px solid #d1d1d1;
    }

    .listItemDrop{
        padding: 20px 20px;
        width: 100%;
        height: auto;
        background-color: #FFF;
        border-radius: 15px;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .listDrop{
        box-sizing: border-box;
        border-radius: 10px;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 0px;
        background-color: #FFF;

        .listDropHeader{
            padding: 15px 10px;
            cursor: pointer;
        }

        svg{
            color:#FB6700;
            cursor: pointer;
        }

        div {
            padding: 5px 5px;
            display: flex;
            justify-content: space-between;

            > span{
                font-weight: bold;
            }
        }

        .dropItem > div{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .end{
            justify-content: end !important;
        }
    }

}

.divEmbbed{
    max-width: 90%;
}


@media screen and (max-width:600px) {

        .docList{
            width: 100% !important;
        }

        .docDropdown{
            width: 90% !important;
        }

        .totalIntercambio{
            max-width: 90%;
        }

        .listDrop{
            max-width:  90% !important;
        }

        .list{
            width: 100% !important;
        }
        .headerList{
            font-size: 14px;
            width: 90% !important;

            button{
                width: 30px !important;
                height: 30px !important;
            }
        }
        .listItem{
            padding: 20px 20px !important;
            width: 90% !important;
            flex-wrap: wrap-reverse;

            .listDrop{
                max-width: 100% !important;
            }
            
        }

    
}

@media screen and (max-width:800px) {
    .container{
        width: 90%;
    }
    .headerList{
        width: 100%;
    }

}
@media screen and (max-width:550px) {

    .saleWrapper{
        width: 90% !important;
    }
    .billBox{
        flex-direction: column-reverse;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;

        .statusBill{
            width: 100% !important;
        }

        div{
            justify-content: flex-start;
            align-items: flex-start;
        }

        section {
            width: 100%;
            button{
                width: 100% !important;
            }

            h3{
                display: none;
            }
        }

        .descMobile{
            display: flex !important;
            justify-content: flex-start;
            width: 100%;
        }
        
        .valortotal{
            justify-content: flex-start !important;
            text-align: start;
            align-items: flex-start !important;
            flex-direction: column-reverse;
            width: 100%;

            span{
                width: 130px !important;
            }

           
        }
    }
}

.exclusivoModal{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999999999999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .box{
        width: 400px;
        height: 300px;
        background-color:#FFF;
        border-radius:15px;
        padding:20px;
        display: flex;
        flex-direction: column;
        gap:20px;

        .header{
            display: flex;

            .close{
                background-color: #FB6700;
                color:#FFF;
                width: 40px;
                height: 35px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

        }

        input[type="text"]{
            width: 100%;
            height: 40px;
            border-radius: 10px;
            border:none;
            background-color: #ececec;
        }
    }
}