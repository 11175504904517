.container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    min-height: 70vh;
    justify-content: center;
    align-items: center;
}

.box{
    svg{
        font-size: 100px;
        color:#FB6700;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 200px;
    max-width: 500px;
    background-color: #FFF;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}