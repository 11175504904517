.header{
    width: 100%;
    height: auto;

    .orange{
        background-color: #FB6700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        .container{
            margin: 0 auto;
            width: 90%;
            align-items: center;
            display: flex;
            justify-content: space-between;
            height: 80px;
        }

        .notificationIcon{
            position: relative;

            .shake{
                color:#FB6700;
                background-color: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px;
                padding: 10px;
                gap: 10px;
                cursor: pointer;
            }

            .shake svg{
                color:#FB6700;
                animation: shake 0.5s ease infinite;
            }
        }

        @keyframes shake {
            0% { transform: translateX(0); }
            25% { transform: translateX(-1px); }
            50% { transform: translateX(1px); }
            75% { transform: translateX(-1px); }
            100% { transform: translateX(1px); }
        }

        .notificationsList{
            margin-left: 0px;
            margin-top: 2px;
            padding:7px;
            width: 270px;
            border-radius: 5px;
            background-color: #F9F9F9;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
            color:#818181;

            svg{
                color:#FB6700;
                display: flex;
                align-items: center;

            }

            .item{
                margin-top:3px;
                padding: 6px;
                display: flex;
                justify-content: space-between;
                margin: auto;
                width: 100%;

       
            }

            .item:hover{     
                cursor: pointer;
                border: 1px solid #c9c9c9;      
                border-radius: 4px;
                color:#2b2b2b;
            }


            span{
                padding: 1px;           
                text-align: center;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                // margin: auto;
                border-radius: 10px;          
            }
            
            .seeAll{
                padding: 5px;
                border-radius: 5px;
                width: 100%;
                background-color: #FB6700;
                cursor: pointer;
                color:#FFF;
                svg{
                    color:#FFF;
                }
            }

            .seeAll span:hover{
                opacity: .9;
                background-color: #FB6700;
                border-radius: 10px !important;
            }
        }
    }

    .black{
        width: 100%;
        background-color: #dc4405;

        .containerSocial{
            margin: 0 auto;
            width: 90%;
            align-items: center;
            display: flex;
            justify-content: space-between;
            color:#FFF;
            height: 45px;

            .social{
                display: flex;
                gap: 10px;


                svg{
                    font-size: 20px;
                    color:#FFF;
                }
            }

            .actions{
                display: flex;
                gap: 15px;

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                }

                svg{
                    font-size: 20px;
                }
            }
        }
    }

    .container{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg{
            color:#FFF;
            font-size: 25px;
            cursor: pointer;
        }
    }
}

.logo{
    cursor: pointer;
    width:50px;
}
.logoBox {
    display: flex;
    gap: 3px;
    align-items:end;
}
.logoBox span{
    color:#FFF;
    font-weight: bold;
    font-size:12px;
    padding-top:5px !important;
    position: relative;

    div{
        position: absolute;
        top: -10px;
        left: 3px;
    }
}

@media screen and (max-width:600px) {
    .black{
        height: auto;
        padding: 10px;
    }

    .containerSocial{  
        height: 90px !important;
        display: flex;
        // flex-direction: column;
        gap: 0px;
        flex-wrap: wrap;

        .actions{
            width: 100%;
            display: flex;
            justify-content: flex-end !important;
        }
    }
}
@media screen and (max-width:400px) {
    .black{
        height: auto;
    }

    .containerSocial{  
        height: 90px !important;
        display: flex;
        gap: 0px;
        flex-wrap: wrap;

        .actions{
            flex-wrap: wrap;
        }
    }
}