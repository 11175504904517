.container{
    width: 1200px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto !important;
    flex-direction: column;
    gap: 30px;

    h2{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }

    .headerList{
        width: 550px;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    .form{
        background-color: #FFF;
        padding: 25px;
        width: 550px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 10px;

        button{
            width: 100%;
            padding: 10px;
            border:none;
            background-color: #FB6700;
            color:#FFF;
            border-radius: 5px;
            cursor: pointer;
        }

        button:hover{
            opacity: .9;
        }
    }
}

@media screen and (max-width:600px) {
    .headerList{
        font-size: 14px;
        width: 100% !important;

        button{
            width: 30px !important;
            height: 30px !important;
        }
    }
    
    .container {
      width: 90%;
    }

    .form {
        width: 100% !important;
    }
}

.textDanger{
    color:red;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
}

.loading{
    top: 0;
    position: absolute !important;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #4e4e4e34;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        width: 50px;
        height: 50px;
        color:#FB6700;
        transform: translate(-50%, -50%);
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
}

.inputbox{
    display: flex;    
    position: relative;
    width: 100% !important;

    .buttonCep{
        width: 14% !important;
    }


    input, select{
        width: 100% !important;
        display: flex;
        padding-top: 24px !important;
        padding-bottom: 8px !important;
        border:none;
        border-radius: 5px;
        padding: 10px;
        background-color: #F0F2F5;


    }

    @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      

    .loadingCep{
        svg{
            animation: rotate 1s linear infinite; 
        }
    }
}

.invalid input{
    border: 1px solid red !important;
}

.invalid select{
    border: 1px solid red !important;
}

.inputbox span{
    position: absolute;
    margin-left: 10px;
    margin-top: 5px !important;
    font-size: 10px;
    color:#FB6700;
    font-weight: bold;
}