*{
    box-sizing: border-box;
}

.descMobile{
    display: none !important;
}

.warning{
    margin-top: 20px;
    background-color: rgb(252, 64, 64);
    color:#FFF;
    border: 1px solid red;
    padding: 15px;
    border-radius: 10px;
}

.container{
    width: 550px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto' !important;
    font-size: 14px;

    select{
        margin-top: 30px;
        width: 100%;
        border: none;
        border-radius: 8px;
        height: 40px;
        border: 1px solid #FB6700;
        cursor: pointer;
        text-indent: 4px;
    }

  
    .saleWrapper{
        margin-top: 20px;
        width: 100%;
        border-radius: 10px;
        color:#FFF;
        justify-content: center;
        text-align: center;


        .saleWrapperHeader{
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px;
            cursor: pointer;
        }

        > h3{
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
        }



    }

    .listWrapper{
        color:#000;
        margin-top: 10px;
        width: 100%;
        padding: 20px;
        background-color: #FFF;
        border-radius: 10px;

        flex-direction: column;
        display: flex;
    

        .billBox{
            width: 100%;
            // border: 2px solid #FB6700;
            padding: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            overflow: hidden;
            color:#848389;            
            background-color: #F0F2F5;

            h3{
                color:#000;
            }

            article{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-weight:700;

                b{
                    color:#FB6700;
                }
            }

            .status{
                width: 100%;
                height: 25px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 0;
                color:#FFF;
                display: none;
            }

            .valortotal{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }


            .statusBill{
                color:#FFF;
                border-radius: 5px;
                padding: 2px;
                width: 100px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                gap: 10px;
            }

            .pago{
                border: 1px solid rgb(84, 182, 84);
                background-color: rgb(84, 182, 84);
            }

            .atrasado{
                background-color: rgb(255, 58, 58);
            }
            .aberto{
                background-color: rgb(128, 128, 128);
            }

            section{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                button{
                    cursor: pointer;
                    border: none;
                    background-color:#FB6700;
                    color:#FFF;
                    width: 250px;
                    padding: 10px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    text-transform: uppercase;
                    font-weight:400;
                }

                button:hover{
                    opacity: .9;
                }
            }

            div{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                span{
                    font-size: 10px;
                }
            }
        }
    }

    .loading{
        top: 0;
        position: absolute !important;
        min-width: 100vw;
        min-height: 100vh;
        background-color: #4e4e4e34;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg{
            width: 50px;
            height: 50px;
            color:#FB6700;
            transform: translate(-50%, -50%);
            animation: spin 2s linear infinite;
        }
    
        @keyframes spin {
            0% {
              transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
              transform: translate(-50%, -50%) rotate(360deg);
            }
          }
    }

    .hr {
        border: none !important;
        border-top: 2px solid #000 !important;
        margin: 20px 0 !important;
      }

    .linerisk{
        text-decoration: line-through;
        color:#818389;
    }

    .orange{
        color:#FB6700;
        font-weight: bold;
    }

    .grey{
        color:#818389;
        font-weight: 300 !important;
    }

    .grey-bg{
        background-color: #F5F6F8;
    }

    .border{
        padding:5px 0px;
        border: 1px solid #818389;
        border-radius:10px;

        div{
            padding: 5px;
        }
    }

    .headerList{
        width: 100%;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    h2{ 
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }


   

}



@media screen and (max-width:800px) {
    .container{
        width: 90%;
    }
    .headerList{
        width: 100%;
    }

}
@media screen and (max-width:550px) {
    .billBox{
        flex-direction: column-reverse;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;

        .statusBill{
            width: 100% !important;
        }

        div{
            justify-content: flex-start;
            align-items: flex-start;
        }

        section {
            width: 100%;
            button{
                width: 100% !important;
            }

            h3{
                display: none;
            }
        }

        .descMobile{
            display: flex !important;
            justify-content: flex-start;
            width: 100%;
        }
        
        .valortotal{
            justify-content: flex-start !important;
            text-align: start;
            align-items: flex-start !important;
            flex-direction: column-reverse;
            width: 100%;

            span{
                width: 130px !important;
            }

           
        }
    }

}
