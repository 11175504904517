.container{
    max-width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto' !important;
    font-size: 13px;

    .orange{
        color:#FB6700;
        font-weight: bold;
    }

    .grey{
        color:#818389;
    }

    .grey-bg{
        background-color: #F5F6F8;
    }

    .border{
        padding:5px 0px;
        border: 1px solid #b2b3b4;
        border-radius:10px;

        div{
            padding: 5px 10px;
        }
    }

    .headerList{
        width: 550px;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    h2{ 
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }

    .list{
        width: 550px;
        margin-top: 30px;
        gap: 20px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column;

        .listItem{
            padding: 20px 20px;
            width: 100%;
            height: auto;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .subItem{
                width: 100%;
                display: flex;
                justify-content: space-between;
                
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    :nth-child(1){
                        font-weight: bold;
                    }
                }

                :nth-child(2){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }
            }

            .total{
                width: 100%;
                border-radius: 10px;
                background-color: #F5F6F8;
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: bold;

                span{
                    padding: 14px;
                }

            }

            button{
                cursor: pointer;
                margin-top:10px;
                background-color: #FB6700;
                color:#FFF;
                border-radius: 5px;
                border:none;
                padding: 10px;
                max-width: 250px;
            }

            button:hover{
                opacity: .9;
            }
        
        }        

    }

    .bottomButtons{
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 2%;

        button{
            width: 48% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }

}

.loading{
    top: 0;
    position: absolute !important;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #4e4e4e34;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        width: 50px;
        height: 50px;
        color:#FB6700;
        transform: translate(-50%, -50%);
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
}

@media screen and (max-width:600px) {

        .list{
            width: 100% !important;
        }
        .headerList{
            font-size: 14px;
            width: 100% !important;

            button{
                width: 30px !important;
                height: 30px !important;
            }
        }
        .listItem{
            padding: 20px 20px !important;
            flex-wrap: wrap-reverse;
            
        }
    
}


