*{
    box-sizing: border-box;
}

.container{
    width: 550px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto' !important;
    font-size: 14px; 
    

    .listWrapper{
        color:#000;
        margin-top: 30px;
        width: 100%;
        padding: 20px;
        background-color: #FFF;
        border-radius: 10px;
        gap:8px;
        flex-direction: column;
        display: flex;   

        .notificationBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: auto;
            width: 100%;

            > div{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
            }
     
            button{
                background-color: #FB6700;
                border-radius: 5px;
                border: none;
                padding: 10px 20px;
                color:#FFF;
                cursor: pointer;
            }

            button:hover{
                opacity: .9;
            }
        }
    }

    .loading{
        top: 0;
        position: absolute !important;
        min-width: 100vw;
        min-height: 100vh;
        background-color: #4e4e4e34;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg{
            width: 50px;
            height: 50px;
            color:#FB6700;
            transform: translate(-50%, -50%);
            animation: spin 2s linear infinite;
        }
    
        @keyframes spin {
            0% {
              transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
              transform: translate(-50%, -50%) rotate(360deg);
            }
          }
    }

    .hr {
        border: none !important;
        border-top: 2px solid #000 !important;
        margin: 20px 0 !important;
      }

    .linerisk{
        text-decoration: line-through;
        color:#818389;
    }

    .orange{
        color:#FB6700;
        font-weight: bold;
    }

    .grey{
        color:#818389;
        font-weight: 300 !important;
    }

    .grey-bg{
        background-color: #F5F6F8;
    }

    .border{
        padding:5px 0px;
        border: 1px solid #818389;
        border-radius:10px;

        div{
            padding: 5px;
        }
    }

    .headerList{
        width: 100%;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    h2{ 
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }


   

}



@media screen and (max-width:800px) {
    .container{
        width: 90%;
    }
    .headerList{
        width: 100%;
    }

}
@media screen and (max-width:550px) {
    .billBox{
        flex-direction: column-reverse;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;

        .statusBill{
            width: 100% !important;
        }

        div{
            justify-content: flex-start;
            align-items: flex-start;
        }

        section {
            width: 100%;
            button{
                width: 100% !important;
            }

            h3{
                display: none;
            }
        }

        .descMobile{
            display: flex !important;
            justify-content: flex-start;
            width: 100%;
        }
        
        .valortotal{
            justify-content: flex-start !important;
            text-align: start;
            align-items: flex-start !important;
            flex-direction: column-reverse;
            width: 100%;

            span{
                width: 130px !important;
            }

           
        }
    }

}
