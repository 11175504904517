.container{    
    height: calc(100vh - 80px);
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto !important;
    flex-direction: column;
    gap: 30px;

    h2{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        svg{
            color:#FB6700;
        }
    }

    .form{
        width: 500px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 10px;
        background-color: #FFF;
        padding: 25px;

        button{
            width: 100%;
            padding: 10px;
            border:none;
            background-color: #FB6700;
            color:#FFF;
            border-radius: 5px;
            cursor: pointer;
        }

        button:hover{
            opacity: .9;
        }

        h6{
            color:#FB6700;
        }

        .radiobox{
            display: flex;
            gap: 10px;

            label{
                display: flex;
                gap: 5px;
            }
        }
    }
}

@media screen and (max-width:800px) {
    .container {
      width: 90%;
    }

    .form {
        width: 100% !important;
    }
}

.textDanger{
    color:red;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
}

.loading{
    top: 0;
    position: absolute !important;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #4e4e4e34;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        width: 50px;
        height: 50px;
        color:#FB6700;
        transform: translate(-50%, -50%);
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
}

.inputbox{
    display: flex;    
    position: relative;
    width: 100% !important;

    input, select{
        width: 100% !important;
        display: flex;
        padding-top: 24px !important;
        padding-bottom: 8px !important;
        border:none;
        border-radius: 5px;
        padding: 10px;
        background-color: #F0F2F5;
    }
}

.invalid input{
    border: 1px solid red !important;
}

.invalid select{
    border: 1px solid red !important;
}

.inputbox span{
    position: absolute;
    margin-left: 10px;
    margin-top: 5px !important;
    font-size: 10px;
    color:#FB6700;
    font-weight: bold;
}

.login{
    display: flex;
    justify-content: center;
    font-size: 12px;
    text-decoration: underline;
    margin-bottom: 20px;

    span{
        cursor: pointer;
    }
}