.footer{

    margin-top: 30px;

    h3{
        color:#FF6900;
    }

    width: 100%;
    background-color: #1D1E26;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    color:#818389;

    hr{
        margin-top: 20px;
        width: 100%;
        border:1px solid #4d4d4d;
        opacity: 0.25;
    }
    


    .social{
        display: flex;
        gap: 10px;
        font-size: 25px;
        margin-top: 20px;
        a{
            color:#686C74;
            text-decoration: none;
        }
    }
}

.copy{
    font-size: 15px;
    padding: 20px;
}