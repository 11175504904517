.bg{
    color:#FFF;
    display: flex;
    justify-content: center;
    gap: 60px;
    align-items:center;
    height: 270px;
    width: 100%;
    text-align: center;

    .flag{ 
        border-radius: 10px;
    }
    
    h1{
        text-align: left;
    }

    div{
        display: flex;
        flex-direction: column;

    }

    button{
        margin-top: 10px;
        width: 200px;
        border-radius: 5px;
        background-color: #2C2C2C;
        border: none;
        color:#FB6700;
        padding: 10px;
        cursor: pointer;
        font-weight: medium;  
    }

    button:hover{
        opacity: .9;
    }
}

.warning{
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    background-color: rgb(255, 188, 188);
    color:red;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
}


.loading{
    top: 0;
    position: absolute !important;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #4e4e4e34;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        width: 50px;
        height: 50px;
        color:#FB6700;
        transform: translate(-50%, -50%);
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
}


.container{
    width: 1000px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    .files{
        background-color: #F0F2F5;
        height: auto;
        padding: 20px;
        border-radius: 10px;

        > div {
            display: flex;
            flex-direction: column;
        }

        span {
            font-size: 14px;            
        }

        h5{
            margin-bottom: 10px;
        }

        a{
            text-decoration: none;
            color:#2C2C2C;
        }
    }

    .headerList{
        width: 100%;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        h2{
            display: flex;
            align-items: center;
            gap: 10px;

            svg{
                color:#FB6700;
            }
        }

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    img{
        width: 100%;
    }

    .chat{
        position: relative;
        margin-top: 15px;
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
        border: 2px solid #FB6700;
        
        .inputMessage textarea{
            width: 100%;
            border-radius: 10px;
            margin-top: 20px;
            background-color: #F0F2F5;
            border: none;
            color:#2C2C2C;
            height: 80px;
            padding: 15px;
        }

        .inputButton{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            .buttonMessage{
                background-color:#FB6700;
                border: none;
                padding: 10px;
                margin-top: 10px;
                border-radius: 5px;
                color:#FFF;
                min-width: 200px;
                cursor: pointer;
            }
            
            .buttonClose{
                background-color:#ff2c2c;
                border: none;
                padding: 10px;
                margin-top: 10px;
                border-radius: 5px;
                color:#FFF;
                min-width: 200px;
                cursor: pointer;
            }

            button:hover{
                opacity: .9;
            }
        }

        .chatArea{
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 400px;
            overflow-y: scroll;
            padding-right: 10px;

            .dayDivider{
                margin-bottom: 10px;
                background-color:#FB6700;
                color:#FFF;
                padding: 5px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
            }

            .messageUser{                
                display: flex;
                justify-content: end;
                align-items: flex-end;
                align-self: flex-end;
                background-color: antiquewhite;
                border-top-right-radius: 0px !important;
            }

            .messageAdmin{                
                display: flex;
                justify-content: start;
                align-items: flex-start;
                align-self: flex-start;
                background-color: rgb(255, 219, 172);
                border-top-left-radius: 0px !important;
            }

            .message{
                position: relative;
                width: auto;
                max-width: 90%;

                padding: 15px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;

                .nome{
                    font-weight: bold;
                    font-size: 12px;
                }

                .timestamp{
                    font-size: 10px;
                }
            }         

         }

        .chatArea::-webkit-scrollbar {
            width: 6px; /* Set the width of the scrollbar */
        }
        
        .chatArea::-webkit-scrollbar-thumb {
        background-color: #FB6700; /* Set the color of the thumb */
        border-radius: 3px; /* Optional: Round the corners of the thumb */
        }
        
        .chatArea::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Set the color of the track */
        }
    }
}



@media screen and (max-width:800px) {
    .bg {
        img{
            display: none;
        }

        button{
            max-width: 90%;
        }
    }

    .container{
        width: 90%;
    }

    
}

@media screen and (max-width:600px) {
    .bg {
        h1{
         font-size: 24px;
        }
    }

    .headerList{
        font-size: 14px;
        width: 100% !important;

        button{
            width: 30px !important;
            height: 30px !important;
        }

    }

    .inputButton{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .buttonMessage{
            background-color:#FB6700;
            border: none;
            padding: 10px;
            margin-top: 10px;
            border-radius: 5px;
            color:#FFF;
            min-width: 48% !important;
            max-width: 48% !important; 
            cursor: pointer;
        }
        
        .buttonClose{
            background-color:#ff2c2c;
            border: none;
            padding: 10px;
            margin-top: 10px;
            border-radius: 5px;
            color:#FFF;
            min-width: 48% !important;
            max-width: 48% !important; 
            cursor: pointer;
        }
    }

}

@media screen and (max-width:300px) {
    
}