.bg{
    color:#FFF;
    display: flex;
    justify-content: center;
    gap: 60px;
    align-items:center;
    height: 270px;
    width: 100%;
    text-align: center;
    border-bottom-left-radius:30px;
    border-bottom-right-radius:30px;

    .flag{ 
        border-radius: 10px;
    }
    
    h1{
        text-align: left;
    }

    div{
        display: flex;
        flex-direction: column;

    }

    button{
        margin-top: 10px;
        width: 200px;
        border-radius: 5px;
        background-color: #2C2C2C;
        border: none;
        color:#FB6700;
        padding: 10px;
        cursor: pointer;
        font-weight: medium;  
    }

    button:hover{
        opacity: .9;
    }
}
.container{
    width: 1000px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    h2{
        margin-bottom: 20px;
    }

    img{
        width: 100%;
    }

    .list{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        a{
            text-decoration: none;
            color:black;
            font-size: 1.5em;

            svg{
                color:#FB6700;
            }
        }


        .listItemInactive{
            width: 20%;
            height: 200px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;

            svg{
                color:#777777;
                fill: #FFF;
            }
        }

                    
        .listItemInactive:hover{
            gap: 15px;
        }

        .listItem{
            width: 20%;
            height: 200px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;
        }

                    
        .listItem:hover{
            gap: 15px;
        }
        .listItemFull{
            width: 47%;
            height: 140px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;

            img{
                width: auto;
            }
        }

                    
        .listItemFull:hover{
            gap: 15px;
        }
    }

}



@media screen and (max-width:800px) {
    .bg {
        img{
            display: none;
        }

        button{
            max-width: 90%;
        }
    }

    .container{
        width: 90%;
    }

    .list{
        flex-wrap: wrap;
        gap: 15px;
        
        a{
            text-decoration: none;
            color:black;
            font-size: 1.5em;

            svg{
                color:#FB6700;
            }
        }


        .listItemInactive{
            min-width: 47%;
            height: 200px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;
        }
        .listItem{
            min-width: 47%;
            height: 200px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;
        }

        .listItemFull{
            flex-direction: column;
        }
    }
}

@media screen and (max-width:400px) {
    .listItemFull{
        width: 100% !important;
    }

    .bg {
        h1{
         font-size: 24px;
        }
    }
    .list{
        flex-wrap: wrap;
        gap: 15px;

        .listItem{
            min-width: 47%;
            height: 150px !important;
            font-size: 1.4rem !important;
        }
    }

    .list{
        margin-bottom: 10px;
    }

    .listItemFull{
        flex-direction: column;
        width: 100%;
        height: 140px;
        flex-direction: column;
        background-color: #FFF;
        border-radius: 15px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        transition: .2s !important;
        text-align: center;
        padding: 5px;
    }
}

@media screen and (max-width:300px) {
    .list{
        flex-wrap: wrap;
        gap: 15px;

        .listItem{
            min-width: 100%;
            height: 150px !important;
            font-size: 1.4rem !important;
        }
    }
}