.bg{
    color:#FFF;
    display: flex;
    justify-content: center;
    gap: 60px;
    align-items:center;
    height: 270px;
    width: 100%;
    text-align: center;
    border-bottom-left-radius:30px;
    border-bottom-right-radius:30px;

    .flag{ 
        border-radius: 10px;
    }
    
    h1{
        text-align: left;
    }

    div{
        display: flex;
        flex-direction: column;

    }

    button{
        margin-top: 10px;
        width: 200px;
        border-radius: 5px;
        background-color: #2C2C2C;
        border: none;
        color:#FB6700;
        padding: 10px;
        cursor: pointer;
        font-weight: medium;  
    }

    button:hover{
        opacity: .9;
    }
}
.container{
    width: 1000px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 30vh;

    .headerList{
        width: 100%;
        text-align: start !important;
        display: flex; 
        justify-content: space-between !important;
        align-items: center;

        h2{
            display: flex;
        }

        button{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: #FB6700;
            color:#FFF;
            transition: transform 0.3s ease-in-out;
        }

        button:hover{
            opacity: .9;
            transform: rotate(-360deg);
        }
    }

    img{
        width: 100%;
    }

    .list{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        a{
            text-decoration: none;
            color:black;
            font-size: 1.5em;

            svg{
                color:#FB6700;
            }
        }


        .listItem{
            width: 48%;
            height: 100px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;
        }

                    
        .listItem:hover{
            gap: 15px;
        }
    }

}



@media screen and (max-width:800px) {
    .bg {
        img{
            display: none;
        }

        button{
            max-width: 90%;
        }
    }

    .container{
        width: 90%;
    }

    .list{
        flex-wrap: wrap;
        gap: 15px;
        
        a{
            text-decoration: none;
            color:black;
            font-size: 1.5em;

            svg{
                color:#FB6700;
            }
        }


        .listItem{
            min-width: 100%;
            height: 100px;
            background-color: #FFF;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            transition: .2s !important;
            text-align: center;
            padding: 5px;
        }
    }
}

@media screen and (max-width:400px) {
    .bg {
        text-align: center !important;
        div{
            align-items: center !important;
            justify-content: center !important;    
            text-align: center !important;
        }
        h1{
         font-size: 24px;
         text-align: center;
        }

    }
    .list{
        flex-wrap: wrap;
        gap: 15px;

        .listItem{
            min-width: 100%;
            height: 150px !important;
            font-size: 1.4rem !important;
        }
    }
}

